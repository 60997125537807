/* eslint-disable @angular-eslint/no-host-metadata-property */
/* eslint-disable @angular-eslint/component-selector */
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';

@Component({
  selector: 'button[mspotMenuItemSelected]',
  templateUrl: './menu-item-selected.component.html',
  styles: [],
  host: {
    class:
      'flex items-center text-left w-full pl-3 pr-9 py-2.5 text-sm hover:bg-gray-100 hover:text-gray-900 relative',
    tabindex: '-1',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuItemSelectedComponent {
  @HostBinding('class')
  classes = 'text-gray-700';

  isSelected = false;

  @Input() set selected(val: boolean) {
    this.isSelected = val;
    this.classes = val ? 'text-gray-900 font-medium' : 'text-gray-700';
  }

  constructor() {}
}
