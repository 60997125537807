import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgIconsModule } from '@ng-icons/core';

import { MenuItemDirective } from './menu-item.directive';
import { MenuItemSelectedComponent } from './menu-item-selected/menu-item-selected.component';
@NgModule({
  imports: [CommonModule, NgIconsModule],
  declarations: [MenuItemDirective, MenuItemSelectedComponent],
  exports: [MenuItemDirective, MenuItemSelectedComponent],
})
export class FrontendSharedUiMenuModule {}
